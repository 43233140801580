import { useAppDispatchV1 } from "@redux/hooks";
import { ComponentProps, useCallback } from "react";
import { modalTypes } from "@constants/modalTypes";
import { createAction } from "@reduxjs/toolkit";

// Typing the props doesn't seem to work well here, but we typed it below in the hook
// This at least lets us get some of the type saftey in the reducer
export const showModal = createAction(
  "modals/show",
  <T extends ModalTypes>(
    modal: T,
    props: ComponentProps<(typeof modalTypes)[T]>,
    closeAll = false
  ) => {
    return {
      payload: {
        component: modal,
        props,
        closeAll,
      },
    };
  }
);

export const dismissModal = createAction(
  "modals/dismiss",
  (ofType: boolean | ModalTypes | unknown = false) => {
    let type: boolean | ModalTypes;
    if (typeof ofType === "boolean" || (typeof ofType === "string" && ModalTypes[ofType])) {
      type = ofType as boolean | ModalTypes;
    } else {
      type = false;
    }
    return {
      payload: {
        ofType: type,
      },
    };
  }
);

type CompProps<T extends ModalTypes> = ComponentProps<(typeof modalTypes)[T]>;

export default () => {
  const dispatch = useAppDispatchV1(),
    _showModal = useCallback(
      <T extends ModalTypes>(modal: T, props?: CompProps<T>, closeAll = false) => {
        dispatch(showModal(modal, props, closeAll));
      },
      [dispatch]
    ),
    _dismissModal = useCallback(
      (...args: Parameters<typeof dismissModal>) => {
        dispatch(dismissModal(...args));
      },
      [dispatch]
    );

  return { showModal: _showModal, dismissModal: _dismissModal };
};

export enum ModalTypes {
  ADD_JOIN_DOMAIN = "ADD_JOIN_DOMAIN",
  ADD_ASSETS_TO_KIT = "ADD_ASSETS_TO_KIT",
  ADD_EXISTING_KIT = "ADD_EXISTING_KIT",
  CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION",
  CHANGE_CARD = "CHANGE_CARD",
  CHANGE_SUBSCRIPTION = "CHANGE_SUBSCRIPTION",
  CHANGE_SUBSCRIPTION_FLOW = "CHANGE_SUBSCRIPTION_FLOW",
  CONFIGURE_REQUESTS_MODAL = "CONFIGURE_REQUESTS_MODAL",
  CONFIRMATION = "CONFIRMATION",
  CREATE_EDIT_LINK = "CREATE_EDIT_LINK",
  CREATE_GALLERY_ITEM = "CREATE_GALLERY_ITEM",
  CREATE_API_TOKEN = "CREATE_API_TOKEN",
  CREATE_DIRECT_LINK = "CREATE_DIRECT_LINK",
  CREATE_EDIT_ASSET_VIEW = "CREATE_EDIT_ASSET_VIEW",
  CREATE_EDIT_COLOR = "CREATE_EDIT_COLOR",
  CREATE_EDIT_CUSTOM_FIELD = "CREATE_EDIT_CUSTOM_FIELD",
  CREATE_FONT = "CREATE_FONT",
  CREATE_EDIT_KIT_COLLECTION = "CREATE_EDIT_KIT_COLLECTION",
  CREATE_KIT = "CREATE_KIT",
  CREATE_PORTAL = "CREATE_PORTAL",
  CREATE_KIT_VERSION = "CREATE_KIT_VERSION",
  CREATE_SECTION = "CREATE_SECTION",
  CREATE_TEMPLATE_KIT = "CREATE_TEMPLATE_KIT",
  DELETE_PORTAL = "DELETE_PORTAL",
  DELETE_KIT = "DELETE_KIT",
  DELETE_SPACE = "DELETE_SPACE",
  DELETE_SPACE_USER = "DELETE_SPACE_USER",
  EDIT_ASSET_THUMBNAIL = "EDIT_ASSET_THUMBNAIL",
  EDIT_INVOICE = "EDIT_INVOICE",
  EDIT_NEW_USER_ACCESS = "EDIT_NEW_USER_ACCESS",
  EDIT_MOTION_ASSET_THUMBNAIL = "EDIT_MOTION_ASSET_THUMBNAIL",
  EDIT_PORTAL = "EDIT_PORTAL",
  EDIT_KIT = "EDIT_KIT",
  EDIT_KIT_VERSION = "EDIT_KIT_VERSION",
  EDIT_TAG = "EDIT_TAG",
  EDIT_DIRECT_LINK = "EDIT_DIRECT_LINK",
  ENTERPRISE_REQUEST = "ENTERPRISE_REQUEST",
  FIGMA_FRAME_PICKER_MODAL = "FIGMA_FRAME_PICKER_MODAL",
  FIGMA_LINK_MODAL = "FIGMA_LINK_MODAL",
  FIGMA_SETUP = "FIGMA_SETUP",
  INFO_PROMPT = "INFO_PROMPT",
  INVITE_SPACE_MEMBERS = "INVITE_SPACE_MEMBERS",
  LEAVE_SPACE = "LEAVE_SPACE",
  LIBRARY_PICKER = "LIBRARY_PICKER",
  MANAGE_COLLECTION_KITS = "MANAGE_COLLECTION_KITS",
  MANAGE_KIT_COLLECTIONS = "MANAGE_KIT_COLLECTIONS",
  SHARE_KIT = "SHARE_KIT",
  SHARE_PORTAL = "SHARE_PORTAL",
  MANAGE_FILECUTS = "MANAGE_FILECUTS",
  MANAGE_PORTAL_KITS = "MANAGE_PORTAL_KITS",
  MOVE_COPY_TO_KIT = "MOVE_COPY_TO_KIT",
  MOVE_COPY_TO_SECTION = "MOVE_COPY_TO_SECTION",
  MENU_MODAL = "MENU_MODAL",
  PICK_FILE = "PICK_FILE",
  PORTAL_DOMAIN = "PORTAL_DOMAIN",
  PUBLISHED_KIT_META = "PUBLISHED_KIT_META",
  SEARCH_MODAL = "SEARCH_MODAL",
  SELECT_PLAN = "SELECT_PLAN",
  SPACE_REQUEST_EDIT = "SPACE_REQUEST_EDIT",
  SPACE_REQUEST_DENY = "SPACE_REQUEST_DENY",
  SPACE_MEMBER_PERMISSIONS = "SPACE_MEMBER_PERMISSIONS",
  TRANSFER_SPACE = "TRANSFER_SPACE",
  UPLOAD_DETAILS = "UPLOAD_DETAILS",
  TFA_PASSWORD_VERIFY = "TFA_PASSWORD_VERIFY",
  TFA_SETUP = "TFA_SETUP",
  TFA_DISABLE = "TFA_DISABLE",
  TFA_CODES = "TFA_CODES",
  TFA_SUCCESS = "TFA_SUCCESS",
  KIT_NAVIGATION = "KIT_NAVIGATION",
  INSIGHTS_PORTAL = "INSIGHTS_PORTAL",
  INSIGHTS_KIT = "INSIGHTS_KIT",
  INSIGHTS_ASSET = "INSIGHTS_ASSET",
  INSIGHTS_USER = "INSIGHTS_USER",
  INSIGHTS_DOWNLOAD = "INSIGHTS_DOWNLOAD",
  INSIGHTS_DIRECT_LINKS = "INSIGHTS_DIRECT_LINKS",
}
