import React, { useState, useCallback } from "react";
import {
  Button,
  Flex,
  PopupMenu,
  DirectLink,
  copy,
  Box,
  Text,
  SpacePermission,
} from "@thenounproject/lingo-core";
import AccordionSection from "../AccordionSection";
import { Inspectable } from "@constants/Inspector";
import useShowModal, { ModalTypes } from "@redux/actions/useModals";
import { useSelectSpace } from "@redux/selectors/entities/spaces";
import useAssetDirectLinks from "@redux/actions/assets/useAssetDirectLinks";
import useCreateDirectLink from "@redux/actions/assets/useCreateAssetDirectLink";
import useDeleteAssetDirectLink from "@redux/actions/assets/useDeleteAssetDirectLink";
import useNotifications from "@actions/useNotifications";

type InspectorDirectLinksProps = {
  inspectable: Inspectable;
};

const InspectorDirectLinks: React.FC<InspectorDirectLinksProps> = ({ inspectable }) => {
  const space = useSelectSpace();
  const [showOverflowMenu, setShowOverflowMenu] = useState<boolean>(false);
  const [copiedButton, setCopiedButton] = useState<number | null>(null);
  const [overflowLink, setOverflowLink] = useState<DirectLink>(null);
  const { showModal } = useShowModal();
  const { showNotification } = useNotifications();
  const [createAssetDirectLink] = useCreateDirectLink();
  const [deleteAssetDirectLink] = useDeleteAssetDirectLink();
  const canManageDirectLinks = space.access.permissions.includes(SpacePermission.manageDirectLinks);

  const toggleOverflowMenu = useCallback(
    (link: DirectLink) => {
      setOverflowLink(link);
      setShowOverflowMenu(!showOverflowMenu);
    },
    [showOverflowMenu]
  );

  const { asset } = inspectable;
  const { data } = useAssetDirectLinks({ assetId: asset.id }, { skip: !asset.id });
  const directLinks = data?.directLinks;

  const handleCopyLink = useCallback((link: DirectLink) => {
    setCopiedButton(link.id);
    copy(link.url);
    setTimeout(() => {
      setCopiedButton(null);
    }, 3000);
  }, []);

  const handleOpenCreateModal = useCallback(() => {
    showModal(ModalTypes.CREATE_DIRECT_LINK, { space, assetId: asset.id });
  }, [showModal, space, asset]);

  const handleOpenEditModal = useCallback(() => {
    showModal(ModalTypes.EDIT_DIRECT_LINK, { space, directLink: overflowLink });
  }, [showModal, space, overflowLink]);

  const handleCreateLink = useCallback(async () => {
    if (directLinks?.length) {
      handleOpenCreateModal();
    } else {
      const res = await createAssetDirectLink({ assetId: asset.id, name: null });
      if (res.error) {
        const message = res.error?.message || "Failed to create link";
        showNotification({ message, level: "error" });
      }
    }
  }, [
    directLinks?.length,
    handleOpenCreateModal,
    createAssetDirectLink,
    asset.id,
    showNotification,
  ]);

  const handleDeleteLink = useCallback(
    async ({ assetId, linkId }) => {
      const res = await deleteAssetDirectLink({ assetId, linkId });
      if (res.error) {
        const message = res.error?.message || "Failed to delete link";
        showNotification({ message, level: "error" });
      }
    },
    [deleteAssetDirectLink, showNotification]
  );

  function renderDirectLinks() {
    if (!directLinks?.length)
      return (
        <Box background="grayLightest" borderRadius="4px" p="6px 16px" textAlign="center">
          <Text font="ui.small" color="grayDark">
            No links created
          </Text>
        </Box>
      );
    return (
      <Flex flexDirection="column" gap="4px">
        {directLinks.map(link => {
          const isCopied = copiedButton === link.id;
          const linkName = link?.name || "Default";
          return (
            <Flex key={link.id} gap="4px">
              <Button
                text={isCopied ? "Copied" : linkName}
                size="small"
                width="100%"
                onClick={() => handleCopyLink(link)}
                icon={isCopied ? null : "action.copy"}
                buttonStyle="secondary"
              />
              <Button
                size="small"
                icon="overflow"
                disabled={!canManageDirectLinks}
                onClick={() => toggleOverflowMenu(link)}
                data-popup-source={`link-overflow-${link.id}`}
                buttonStyle="secondary"
              />
            </Flex>
          );
        })}
        {showOverflowMenu ? (
          <PopupMenu
            source={`link-overflow-${overflowLink.id}`}
            vPos="floatBelow"
            hPos="alignLeft"
            close={toggleOverflowMenu}>
            <PopupMenu.Item title="Edit link" onClick={handleOpenEditModal} />
            <PopupMenu.Item
              title="Delete link"
              onClick={() =>
                handleDeleteLink({ assetId: overflowLink.assetId, linkId: overflowLink.id })
              }
            />
          </PopupMenu>
        ) : null}
      </Flex>
    );
  }
  return (
    <AccordionSection
      title="Direct Links"
      contentId={asset.id}
      disableControls
      buttonText={canManageDirectLinks && "Create Link"}
      onButtonClick={handleCreateLink}>
      {renderDirectLinks()}
    </AccordionSection>
  );
};

export default InspectorDirectLinks;
