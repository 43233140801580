import { createQueryAction } from "@redux/actions/actionCreators";
import { API, DirectLink } from "@thenounproject/lingo-core";

type Args = {
  assetId: string;
};

type Result = { directLinks: DirectLink[] };
type NormalizedResult = { directLinks: number[] };

const [useAssetDirectLinks, fetchAssetDirectLinks] = createQueryAction<
  Args,
  Result,
  NormalizedResult
>(
  {
    entity: "directLinks",
    action: "fetch",
    denormalize: { entity: null, directLinks: "directLinks" },
    condition: ({ args }) => Boolean(args.assetId),
  },
  async ({ args }) => {
    const res = await API.call<NormalizedResult>({
      endpoint: `assets/${args.assetId}/direct_links`,
      method: "GET",
      entity: API.Entity.directLinks,
    });
    return res;
  }
);

export default useAssetDirectLinks;
export { fetchAssetDirectLinks };
